<script>
/* eslint-disable */
import {FormWizard, TabContent} from '@anivive/vue3-form-wizard';
// import vue2Dropzone from "vue2-dropzone";

import appConfig from '@/app.config';
import PageHeader from '@/components/page-header';
import Layout from '../../../layouts/main';

/**
 * KYC Application component
 */
export default {
  methods: {
    onComplete() {
      this.$refs.iswizard.reset();
    },
  },
  page: {
    title: 'KYC Application',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {
    Layout,
    PageHeader,
    // vueDropzone: vue2Dropzone,
    FormWizard,
    TabContent,
  },
  mounted() {
  },
  data() {
    return {
      title: 'KYC Application',
      items: [
        {
          text: 'Crypto',
          href: '/',
        },
        {
          text: 'KYC Application',
          active: true,
        },
      ],
      showModal: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 120,
        maxFilesize: 0.5,
        headers: {'My-Awesome-Header': 'header value'},
      },
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row justify-content-center mt-lg-5">
      <div class="col-xl-5 col-sm-8">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <h4 class="mt-4 fw-semibold">KYC Verification</h4>
                  <p class="text-muted mt-3">
                    Itaque earum rerum hic tenetur a sapiente delectus ut aut
                    reiciendis perferendis asperiores repellat.
                  </p>
                  <div class="mt-4">
                    <!-- Button trigger modal -->
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="showModal = true"
                    >
                      Click here for Verification
                    </button>
                  </div>
                </div>
                <div class="row justify-content-center mt-5 mb-2">
                  <div class="col-sm-6 col-8">
                    <div>
                      <img
                        alt
                        class="img-fluid"
                        src="@/assets/images/verification-img.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showModal"
      centered
      hide-footer
      size="lg"
      title="Verify your Account"
    >
      <form-wizard
        ref="iswizard"
        color="#556ee6"
        shape="tab"
        @on-complete="onComplete"
      >
        <tab-content
          class="kyc-wizard"
          icon="mdi mdi-account-circle"
          title="Personal Info"
        >
          <form>
            <div class="row">
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="kycfirstname-input">First name</label>
                  <input
                    id="kycfirstname-input"
                    class="form-control"
                    placeholder="Enter First name"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="kyclastname-input">Last name</label>
                  <input
                    id="kyclastname-input"
                    class="form-control"
                    placeholder="Enter Last name"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="kycphoneno-input">Phone</label>
                  <input
                    id="kycphoneno-input"
                    class="form-control"
                    placeholder="Enter Phone number"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="kycbirthdate-input">Date of birth</label>
                  <input
                    id="kycbirthdate-input"
                    class="form-control"
                    placeholder="Enter Date of birth"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="mb-3">
                  <label class="form-label" for="kycselectcity-input"
                  >City</label
                  >
                  <select id="kycselectcity-input" class="form-select">
                    <option selected value="SF">San Francisco</option>
                    <option value="LA">Los Angeles</option>
                    <option value="SD">San Diego</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </tab-content>
        <tab-content
          class="kyc-wizard"
          icon="mdi mdi-email"
          title="Confirm Email"
        >
          <form>
            <div class="row">
              <div class="col-lg-12">
                <div class="mb-3">
                  <label for="kycemail-input">Email</label>
                  <input
                    id="kycemail-input"
                    class="form-control"
                    placeholder="Enter Email Address"
                    type="email"
                  />
                </div>

                <div class="mb-3">
                  <label for="kycconfirmcode-input">Confirm code</label>
                  <input
                    id="kycconfirmcode-input"
                    class="form-control"
                    placeholder="Enter Confirm code"
                    type="email"
                  />
                </div>

                <div class="mb-3">
                  Didn't recieve code ?
                  <button class="btn btn-link" type="button">
                    Resend Code
                  </button>
                </div>
              </div>
            </div>
          </form>
        </tab-content>
        <tab-content
          class="kyc-wizard"
          icon="mdi mdi-checkbox-marked-circle-outline"
          title="Document Verification"
        >
          <h5 class="font-size-14 mb-3">
            Upload document file for a verification
          </h5>
          <div class="kyc-doc-verification">
            <!-- <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </vue-dropzone> -->
            <!-- end row -->
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </Layout>
</template>
